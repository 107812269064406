import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./PdfViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdf }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  let styleButton={
    width: "100px",
    height: "40px",
    borderRadius:"6px",
    behavior:"url('css/PIE.htc')",
    border: "1px solid #1ea6ef;background:#1ea6ef",
    outline:"none",
    fontSize:"14px",
    fontWeight:"bold",
    color:"#ffffff",
    marginLeft:"8px",
    position:"relative",
    background:"#4f6d83"
  }
  return (
    <div>
      <div className="controls">
     

      <Document
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e) => e.preventDefault()}
        className="pdf-container"
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <button onClick={prevPage} disabled={pageNumber === 1} style={styleButton}>
          Prev
        </button>
        <button onClick={nextPage} disabled={pageNumber === numPages} style={styleButton}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PdfViewer;

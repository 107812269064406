import React from "react";
import PdfViewer from "./PdfViewer";
import PDF from "./pilot_modeltest02_ss";
import "./App.css";
import crypto from 'crypto-js';

const windowUrl = window.location.search;

const params = new URLSearchParams(windowUrl);
const name = params.get('t');

const ciphertext = crypto.AES.encrypt(name, 'kau').toString();

const bytes = crypto.AES.decrypt(ciphertext, 'kau').toString();
const originalText = bytes.toString(crypto.enc.Utf8); // output: '암호화할 값'



//console.log(name);

console.log(ciphertext);
console.log(originalText);

const App = (props) => (

  <div className="content">
    <PdfViewer pdf={'/static/media/'+name} onZoom="true" />
  </div>

  
);

export default App;
